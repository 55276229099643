import { useEffect, useState } from "react";
import { SingleImageDropzoneUsage } from "./components/SingleImageDropZoneUsage";
import { api } from "./lib/fetchInstance";
import Card from "./components/Card";

function App() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    api.get("/images").then(res => setImages(res));
  }, []);
  return (
    <div className="py-8 px-4 max-w-7xl mx-auto">
      <div>
        <SingleImageDropzoneUsage images={images} setImages={setImages} />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
        {images.map(image => (
          <Card
            image={image}
            key={image.id}
            images={images}
            setImages={setImages}
          />
        ))}
      </div>
    </div>
  );
}

export default App;
