// api.js

export const apiBaseUrl =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:4000";

// Define a function for making GET requests
function get(endpoint) {
  return fetch(`${apiBaseUrl}/api/${endpoint}`).then(handleResponse);
}

// Define a function for making POST requests
function post(endpoint, data) {
  return fetch(`${apiBaseUrl}/api/${endpoint}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then(handleResponse);
}
function postFormData(endpoint, formData) {
  return fetch(`${apiBaseUrl}/api/${endpoint}`, {
    method: "POST",
    body: formData,
  }).then(handleResponse);
}
// Define a function for making PUT requests
function put(endpoint, data) {
  return fetch(`${apiBaseUrl}/api/${endpoint}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then(handleResponse);
}

// Define a function for making DELETE requests
function remove(endpoint) {
  return fetch(`${apiBaseUrl}/api/${endpoint}`, {
    method: "DELETE",
  }).then(handleResponse);
}

// Helper function to handle responses
function handleResponse(response) {
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
}

// Export the API methods
export const api = {
  get,
  post,
  put,
  remove,
  postFormData,
};
